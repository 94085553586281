import Appsignal from '@appsignal/javascript';

const KEYS = {
  'dev1.showmojo.com': '',
  'dev2.showmojo.com': 'caf45412-aa84-4d33-a91a-3e3d0c446ecc',
  'dev3.showmojo.com': 'fb57caeb-5f4b-41a5-aca8-0a9cbf0a1ea1',
  'dev4.showmojo.com': '17dd6359-05ca-4549-bb9e-c54d92ca0d52',
  'dev5.showmojo.com': '',
  'dev6.showmojo.com': '',
  'staging.showmojo.com': '',
  'showmojo.com': 'c9d5b982-077d-4609-b320-f8147ac9e7a1',
}

export const appsignal = new Appsignal({
  key: KEYS[window.location.host],
  revision: '1'
});
