import LayoutUtils from 'src/utils/layout_utils';
window.LayoutUtils = LayoutUtils;
import Quill from 'quill';
window.Quill = Quill;
import FetchUtils from 'src/utils/fetch_utils';
window.FetchUtils = FetchUtils;

import DashboardUtils from 'src/utils/dashboard_utils';
window.DashboardUtils = DashboardUtils;
import RequiredListingUpdatesUtils from 'src/application/listings/required_listing_updates/utils';
window.RequiredListingUpdatesUtils = RequiredListingUpdatesUtils;

//TODO:: remove when all js will be replaced to /javascripts folder
import Dropzone from 'dropzone';
window.Dropzone = Dropzone;

import SMDropdown from  'src/components/dropdown/sm-dropdown';
window.SMDropdown = SMDropdown;
