import { appsignal } from './appsignal';

class FetchUtils {
  static get GENERAL_ERROR_MESSAGE() {
    return 'Something went wrong. Please try again later.';
  }
  static checkResponseStatus(response) {
    if (response.status === 401) {
      // Session expired, so reload will save the last page address
      // and move to login page.
      location.reload();
    } else if (response.status === 404) {
      return Promise.reject({smErrText: 'Page not found.', status: 404});
    } else if (!response.ok) {
      return Promise.reject({smErrText: FetchUtils.GENERAL_ERROR_MESSAGE, status: response.status});
    }
    return response;
  }

  static handleResponseError(err) {
    console.log(err);
    appsignal.sendError(new Error(err.smErrText ? err.smErrText : err.message));
  }

  static CSRFToken() {
    return document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  }
}

export default FetchUtils;
